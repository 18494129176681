import React from 'react';
import { connect } from 'react-redux';
import { Block, Page, Navbar, NavLeft, Link, NavTitle, NavRight, PageContent } from 'framework7-react';
import { PkCmsContents } from 'pikkart-cms';
import ContentListItem from './content-list-item';
import Commons from '../commons'
import appLocaleStrings from '../../app-local'

class ContentList extends React.Component {

    constructor(props) {
        super(props);

        this.page = 0;
        this.pageSize = 10;

        this.state = {
            errorMessage: null,
            infoContentsData: [],
            allowInfinite: true,
            showPreloader: true,
        };
    }

    render() {

        let menuItem = Commons.getMenuItemFromRoute(this.$f7route, this.props.menu);

        return (
            <Page
                infinite
                infinitePreloader={this.state.showPreloader}
                onInfinite={() => this.fetchInfoContents()}
            >
                <Navbar>
                    <NavLeft>
                        <Link panelOpen="left" color="black" iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu"></Link>
                    </NavLeft>
                    <NavTitle>{menuItem.title}</NavTitle>
                    <NavRight>
                        {this.getBackButton(menuItem)}
                    </NavRight>
                </Navbar>

                <div>
                    {this.getInfoContentsData()}
                </div>

            </Page>
        )
    }

    getBackButton = (item) => {
        if (item.catIndex > 1) {
            return (<Link back iconIos="f7:arrow_left" iconAurora="f7:arrow_left" iconMd="material:arrow_back" ></Link>);
        }
        else {
            return null;
        }
    }


    getInfoContentsData = () => {
        // console.log("getInfoContentsData");
        if (this.state.infoContentsData === null ||
            this.state.infoContentsData.length === 0) {
            if (this.state.errorMessage) {
                return (

                    <Block style={{ textAlign: 'center', color: '#666' }}>
                        {String(this.state.errorMessage)}
                    </Block>
                );
            }
            else
                return null;
        }

        const ret = this.state.infoContentsData.map((item, index) => {

            return (<ContentListItem
            	isFavourites={this.props.menuItem.code === '/preferiti'}
                contentData={item}
                key={'info-content-' + index} onCardClickHandler={(item) => {
                    this.onCardClick(item);
                }}></ContentListItem>);
        });

        return (
            <Block style={{ padding: '0' }}>
                <PageContent style={{ padding: '0' }}>{ret}</PageContent>
            </Block>)
    }

    onCardClick = (item) => {
        this.$f7router.navigate(Commons.getContentDetailUrl(item.content), { props: { contentItem: item } });
    }

    componentDidMount = () => {
        //TODO: una volta fatto il componente separato i dati gli vengono passati indipendentemente dal fatto che siano in locale o da scaricare
        if (this.props.menuItem.code === '/preferiti') {
            const jsonSringFavoriteContents = localStorage.getItem('favorite_contents');
            if (jsonSringFavoriteContents) {
                const favoritesContents = JSON.parse(jsonSringFavoriteContents);
                if (favoritesContents.length > 0) {
                    this.setState({ infoContentsData: favoritesContents, errorMessage: null, showPreloader: false, allowInfinite: false });
                }
                else {
                    this.noFavoritesContents();
                }
            }
            else {
                this.noFavoritesContents();
            }
        } else if (this.state.infoContentsData === null || this.state.infoContentsData.length === 0) {
            this.fetchInfoContents();
        }
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this.props.menuItem.code === '/preferiti') {
            if (this.props.favoriteContents && this.props.favoriteContents !== prevProps.favoriteContents) {
                if (this.props.favoriteContents.length > 0) {
                    this.setState({ infoContentsData: this.props.favoriteContents, errorMessage: null, showPreloader: false, allowInfinite: false });
                }
                else {
                    this.noFavoritesContents();
                }
            }
        }
    }

    noFavoritesContents = () => {
        this.setState({
            showPreloader: false,
            allowInfinite: false,
            errorMessage: appLocaleStrings.no_contents,
            infoContentsData: this.props.favoriteContents,
            // infoContentsData: []
        });
    }

    fetchInfoContents = () => {
        if (!this.state.allowInfinite) return;
        this.setState({ allowInfinite: false });

        this.page += 1;

        console.log("loading page " + this.page);

        const errorFn = (errorMsg) => {
            if (this.page !== 1) {
                this.setState({ showPreloader: false });
                return;
            }
            this.setState({
                errorMessage: errorMsg,
                // infoContentsData: []
            });

            this.setState({ showPreloader: false });

            this.setState({
                allowInfinite: true,
            });
        }

        const successFn = (infoContentsData) => {
            this.setState({ infoContentsData: this.state.infoContentsData.concat(infoContentsData), errorMessage: null });

            this.setState({ showPreloader: false });

            if (infoContentsData.length === this.pageSize)
                this.setState({
                    allowInfinite: true,
                });
        }

        PkCmsContents.getInfoContentsDataPage({ c1: this.props.c1, c2: this.props.c2, c3: this.props.c3, page: this.page, pageSize: this.pageSize })
            .then((response) => {
                if (response.result.success === true) {
                    if (response.data.items.length === 0) {
                        if (this.state.infoContentsData.length > 0) {
                            this.setState({ showPreloader: false });
                            return;
                        }
                        errorFn(appLocaleStrings.no_contents);
                    }
                    else
                        successFn(response.data.items);
                } else {
                    errorFn(response.result.message);
                }
            })
            .catch((errorMessage) => {
                errorFn(errorMessage);
            });
    };
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
        favoriteContents: state.app.favoriteContents
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ContentList);