import React from 'react';
import { connect } from 'react-redux';
import { Block, Card, CardContent, CardHeader, SkeletonBlock } from 'framework7-react';

class ContentListItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            effect: 'blink',
        };
    }

    render() {

        let item = this.props.contentData;

        const cardHeader = (item.content.area ? <CardHeader className="categoria" style={{ borderBottomColor: item.content.areaColor }}>
            <span style={{ backgroundColor: item.content.areaColor }}>{item.content.area}</span>
        </CardHeader> : null);
        const contentDescr = (item.content.descr) ? (<CardContent style={{ padding: '16', margin: '0' }}>
            <Block style={{ padding: '0', margin: '0' }} textAlign={'center'}>
                <p>
                    {item.content.descr.length < 100 ? item.content.descr : item.content.descr.substring(0, 100) + '...'}
                </p>
            </Block>
        </CardContent>) : null;


        const skel = (this.state.loading) ? `skeleton-text skeleton-effect-${this.state.effect}` : "";

        let image = () => {
            if (this.props.isFavourites)
                return (
                    <figure className="lazy lazy-fadeIn" style={{ backgroundImage: 'url(' + item.content.imageUrl + ')', alignContent: 'center', objectFit: 'center/cover', maskPosition: 'no-repeat' }}>
                        <h2 className="title card-content-padding">{item.content.title}</h2>
                    </figure>
                );
            return (
                <LazyBackgroundImage item={item} component={this} >
                    {this.state.loading ? < SkeletonBlock style={{ width: '40px', height: '40px', 'border-radius': '50%' }} slot="media" /> : null}
                </LazyBackgroundImage>);

        }

        return (
            <Card noShadow={true} noBorder={true}>
                {cardHeader}
                <CardContent className={skel} style={{ padding: '0' }}>
                    <span onClick={(e) => { this.onCardClick(item) }}>

                        {image()}

                        {/* <figure className="lazy lazy-fadeIn" style={{ backgroundImage: 'url(' + item.content.imageUrl + ')', alignContent: 'center', objectFit: 'center/cover', maskPosition: 'no-repeat' }}>
                            <h2 className="title card-content-padding">{item.content.title}</h2>
                        </figure> */}
                    </span>
                </CardContent>
                {contentDescr}
            </Card>
        )
    }

    onCardClick = (item) => {
        if (this.props.onCardClickHandler) {
            this.props.onCardClickHandler(item);
        }
    }

    componentDidMount = () => {

    }

    imageLoaded = () => {
        console.log('image loaded');
        this.setState({ loading: false });
    }
};


const LazyBackgroundImage = ({ item, component }) => {
    const [source, setSource] = React.useState("preload.jpg")

    React.useEffect(() => {
        const img = new Image()
        img.src = item.content.imageUrl
        img.onload = () => {
            setSource(item.content.imageUrl)
            component.imageLoaded();
        }
    }, [])

    return (
        <figure style={{ backgroundImage: 'url(' + source + ')', alignContent: 'center', objectFit: 'center/cover', maskPosition: 'no-repeat' }}>
            <h2 className="title card-content-padding">{item.content.title}</h2>
        </figure>
    )
}

// #region Redux
const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ContentListItem);