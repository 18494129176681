import { PkCordova, PkCordovaAnalytics } from 'pikkart-cordova';

export class AnalyticsRegister {
    static logEvent = (name, params) => {
        if (PkCordova.isCordova())
            PkCordovaAnalytics.logEvent(name, params);
    }

    static setCurrentScreen = (name) => {
        if (PkCordova.isCordova())
            PkCordovaAnalytics.setCurrentScreen(name);
    }
}

export const ANALYTICS_EVENT = {
    OPEN_CONTENT_DETAIL: 'Open Content Detail',
    OPEN_CONTENT_KML: 'Open Content Kml',
    ADD_TO_FAVORITES: 'Add To Favorites',
    SAVE_PROFILE_DATA: 'Save Profile Data',
};


export const ANALYTICS_SCREEN = {
    MAIN: 'Main',
    PROFILE: 'Profile',
    CREDITS: 'Credits',
    TUTORIAL: 'Tutorial',
    NOTIFICATIONS: 'Notifications',
    CONTENT_KML: 'Content KML',
    CONTENT_DETAIL: 'Content Detail'
};

export default AnalyticsRegister;