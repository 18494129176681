import React from 'react';
import { connect } from 'react-redux';
import { Page, Navbar, NavLeft, Link, NavTitle } from 'framework7-react';
import PikkartTutorial from '../../components/pk-tutorial';
import localeStrings from './tutorial-local';
import { AnalyticsRegister, ANALYTICS_SCREEN } from '../analytics-register'

class Tutorial extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {

        const slides = [
            { image: './img/tutorial1-disc.png', text: localeStrings.tutorial_1 },
            { image: './img/tutorial2.png', text: localeStrings.tutorial_2 },
        ];

        const showToggle = false;
        const toggleText = localeStrings.tutorial_toggle_label;
        const showButton = false;
        const buttonText = localeStrings.tutorial_button_label;

        return (
            <Page>
                <Navbar>
                    <NavLeft>
                        <Link panelOpen="left" color="black" iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu"></Link>
                    </NavLeft>
                    <NavTitle>{localeStrings.title}</NavTitle>
                </Navbar>
                <PikkartTutorial slides={slides} toggleText={toggleText} buttonText={buttonText} showToggle={showToggle} showButton={showButton} />
            </Page>
        )
    }

    componentDidMount = () => {
        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.TUTORIAL);
    }
};

// #region Redux
const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Tutorial);