import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        title: "Tutorial",
        tutorial_toggle_label: "Do not show again",
        tutorial_button_label: "Start the tour",
        tutorial_1: "Point the camera at artifacs inside the museum",
        tutorial_2: "You will access exclusive digital content: videos, image galleries, information cards and much more ...!",
    },
    it: {
        title: "Tutorial",
        tutorial_toggle_label: "Non mostrare più",
        tutorial_button_label: "Comincia la visita",
        tutorial_1: "Inquadra gli artefatti all'interno del museo",
        tutorial_2: "Accederai a contenuti digitali esclusivi: video, gallerie di immagini, schede informative e molto altro...!",
    }
});

export default localeStrings;