import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        title: "Messages",
    },
    it: {
        title: "Notifiche",
    }
});

export default localeStrings;