
export class Commons {

    static getMenuItemUrl = (item) => {
        let stringLink = item.code ? item.code : (item.items.length === 0 ? "/content-list/" : "/menu-list/");
        stringLink += '?cid='+item.id;
        return stringLink;
    }

    static getContentDetailUrl = (content) => {
        let stringLink = "/content-detail/" + content.id;
        return stringLink;
    }
    
    static getRequestedContentDetailUrl = (content) => {
        let stringLink = "/requested-content-detail/" + content.id;
        return stringLink;
    }

    static getMenuItemFromId = (itemId, list, catIndex) => {

        catIndex = (!catIndex) ? 1 : catIndex + 1;

        for (let i=0; i < list.length; i++) 
        {
            let item = list[i];
            if (item.id === itemId) {
                item.catIndex = catIndex;
                return item;
            }
            
            if (item.items && item.items.length > 0) {
                item = Commons.getMenuItemFromId(itemId, item.items, catIndex);
                if (item !== null) {
                    return item;
                }    
            }
        }
        return null;
    }

    static getMenuItemFromCode = (itemCode, list, catIndex) => {

        catIndex = (!catIndex) ? 1 : catIndex + 1;

        for (let i=0; i < list.length; i++) 
        {
            let item = list[i];
            console.log('itemCode='+itemCode+' item.code='+item.code);

            if (item.code === itemCode) {
                item.catIndex = catIndex;
                return item;
            }
            
            if (item.items && item.items.length > 0) {
                item = Commons.getMenuItemFromCode(itemCode, item.items, catIndex);
                if (item !== null) {
                    return item;
                }    
            }
        }
        return null;
    }

    static getMenuItemFromRoute = (route, menu) => {
        let item = null;

        if (route.query && route.query.cid) {
            let cid = parseInt(route.query.cid);
            if (!isNaN(cid)) {
                item = Commons.getMenuItemFromId(cid, menu);
            }
        }

        if (!item) {
            item = Commons.getMenuItemFromCode(route.path, menu);
        }

        return item;
    }


    
}

export default Commons;