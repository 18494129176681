import React from 'react';
import { Page, SwiperSlide, Swiper, Toggle, Button } from 'framework7-react';

class PikkartTutorial extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        const swiperSlides = this.props.slides.map((item, index) => {
            return (
                <SwiperSlide key={index}>
                    <div style={{ textAlign: 'center' }}>
                        <img src={item.image} alt={'tutorial-' + index + '-page'} style={{ width: '80%' }} />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        {item.text}
                    </div>
                </SwiperSlide>
            );
        });

        const getToggle = () => {
            if (this.props.showToggle)
                return (
                    <div style={{ textAlign: 'right' }}>
                        <span>{this.props.toggleText}</span>
                        <Toggle style={{margin:"10px"}} onToggleChange={(event) => { this.props.toggleClicked(event) }} init color="red"></Toggle>
                    </div>
                )
            else
                return null;
        };

        const getButton = () => {
            if (this.props.showButton)
                return (
                    <Button onClick={(e) => { this.props.buttonClicked() }} style={{ marginTop: '15px' }} className="col button button-raised button-fill color-red popup-close">{this.props.buttonText}</Button>
                )
            else
                return null;
        };

        return (
            <Page>
                <Swiper pagination style={{height: "70%"}} params={{ observer: true, observeParents: true }}>
                    {swiperSlides}
                </Swiper>

                {getToggle()}

                {getButton()}
            </Page>
        );
    }

};

export default PikkartTutorial;