
import appActionTypes from './actionTypes';

const initialState = {
    orientation: null,
    notificationServiceError: null,
    menu: [],
    skippedArLogoTutorial: false,  //true: skip tutorial     false: see tutorial
    skippedDiscoverTutorial: false,  //true: skip tutorial     false: see tutorial
    receivedNotifications: [],
    favoriteContents: []
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {

        case appActionTypes.SET_ORIENTATION:
            return {
                ...state,
                orientation: action.orientation,
            }
        case appActionTypes.SET_NOTIFICATION_SERVICE_ERROR:
            return {
                ...state,
                notificationServiceError: action.notificationServiceError,
            }
        case appActionTypes.SET_MENU:
            return {
                ...state,
                menu: action.menu,
            }
        case appActionTypes.SET_SKIPPED_AR_LOGO_TUTORIAL:
            return {
                ...state,
                skippedArLogoTutorial: action.skippedArLogoTutorial,
            }
        case appActionTypes.SET_SKIPPED_DISCOVER_TUTORIAL:
            return {
                ...state,
                skippedDiscoverTutorial: action.skippedDiscoverTutorial,
            }
        case appActionTypes.SET_RECEIVED_NOTIFICATIONS:
            return {
                ...state,
                receivedNotifications: action.receivedNotifications,
            }
        case appActionTypes.SET_FAVORITE_CONTENTS:
            return {
                ...state,
                favoriteContents: action.favoriteContents,
            }

        default:
        //     console.log("Action sconosciuta: " + action.type);
    }
    return state;
};

export default appReducer;