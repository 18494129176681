import React from 'react';
import { connect } from 'react-redux';
import { Page, Navbar, NavRight, Link, NavLeft, NavTitle, Block, PageContent } from 'framework7-react';
import Commons from '../commons'
import ContentListItem from './content-list-item'; 

class MenuList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {

        let item = Commons.getMenuItemFromRoute(this.$f7route, this.props.menu);

        return (
            <Page>
                <Navbar>
                    <NavLeft>
                        <Link panelOpen="left" color="black" iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu"></Link>
                    </NavLeft>
                    <NavTitle>{item.title}</NavTitle>
                    <NavRight>
                        {this.getBackButton(item)}
                    </NavRight>
                </Navbar>

                {this.getItemList(item)}
            </Page>
        )
    }

    getBackButton = (item) => {
        if (item.catIndex > 1) {
            return (<Link back iconIos="f7:arrow_left" iconAurora="f7:arrow_left" iconMd="material:arrow_back" ></Link>);
        }
        else {
            return null;
        }
    }

    getItemList = (item) => {

        const itemData = item.items;
        const ret = itemData.map((item, index) => {
            
            return (<ContentListItem 
                contentData={{content: item}}
                key={'info-content-' + index} onCardClickHandler={(item) => {
                this.onCardClick(item) ;
            }}></ContentListItem>);
        });

        return (
            <Block style={{ padding: '0' }}>
                <PageContent style={{ padding: '0' }}>{ret}</PageContent>
            </Block>)
    }

    onCardClick = (item) => {
        this.$f7router.navigate(Commons.getMenuItemUrl(item.content));
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
      menu: state.app.menu
    };
  };

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(MenuList);