import React from 'react';
import { connect } from 'react-redux';
import { Block, BlockHeader, Page, Navbar, NavLeft, Link, NavTitle } from 'framework7-react';
import localeStrings from './credits-local';
import { AnalyticsRegister, ANALYTICS_SCREEN } from '../analytics-register'

class Credits extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <Page>
                <Navbar>
                    <NavLeft>
                        <Link panelOpen="left" color="black" iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu"></Link>
                    </NavLeft>
                    <NavTitle>{localeStrings.title}</NavTitle>
                </Navbar>
                <BlockHeader>{localeStrings.editorial_staff}</BlockHeader>
                <Block strong>
                    <p><i>Comune di Reggio Emilia</i><br />Piazza Prampolini, 1<br />42121 Reggio Emilia</p>
                    <p onClick={() => { window.open("tel:0522 456111", '_system') }}>0522 456111</p>
                    <p onClick={() => { window.open("https://www.comune.re.it", '_blank') }}>https://www.comune.re.it</p>

                    <div style={{ textAlign: 'center' }}
                    onClick={() => { window.open("http://www.pikkart.com/", '_blank') }}>
                        <img src={'./img/landing-image.png'} alt="logo" width="90%" />
                    </div>
                </Block>
            </Page>
        )
    }

    componentDidMount = () => {
        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.CREDITS);
    }
};

// #region Redux
const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Credits);