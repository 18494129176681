import React from 'react';
import { connect } from 'react-redux';
import { Page, Block, Row, Col } from 'framework7-react';
import * as actions from '../redux/actions';
import { PkCmsStorage, PkCmsNotifications, PkCms, PkCmsContents, TRIGGER_SOURCE_TYPE } from 'pikkart-cms';
import { PkCordova, PkCordovaBeaconReceiver } from 'pikkart-cordova';
import { DateTime } from 'luxon';

class Splash extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    return (
      <Page>
        <Block textAlign="center" >
          <Row style={{ width: '100%' }}>
            <Col style={{ textAlign: 'center' }}>
              <img src={'./img/logo/logo.png'} alt="logo" width="120" />
            </Col>
          </Row>
        </Block>
      </Page>
    )
  }

  componentDidMount = () => {
    //  const self = this;
    this.showPreloader(true);

    if (PkCordova.isCordova()) {
      if (PkCmsStorage.getFirebaseToken() != null) {
        console.log("sending FIREBASE TOKEN to server");
        PkCmsNotifications.sendTokenToServer(PkCmsStorage.getFirebaseToken())
          .then(function () {
            console.log("FIREBASE TOKEN to server: " + PkCmsStorage.getFirebaseToken());
          })
          .catch(function () {
            console.log("FIREBASE TOKEN to server: error");
          });
      } else {
        console.log("no FIREBASE TOKEN to send");
      }

      this.getBeaconList();
    }

    this.fetchContentsMenu();
  }

  startBeaconService = (beacons) => {
    const apiUrl = PkCms.getConfig().apiUrl
    const authorization = PkCms.getConfig().apiKey + ":" + PkCms.getConfig().deviceId;
    const pkDate = DateTime.fromMillis(Date.now()).toUTC().toFormat("y-LL-dd'T'HH:mm:ss");
    if (beacons.length > 0) {
      PkCordovaBeaconReceiver.startBeaconSearch(apiUrl, authorization, pkDate, beacons);
    }
    else {
      console.log("Non sono presenti beacon");
    }
  }

  getBeaconList = () => {
    const self = this;
    PkCmsContents.getTriggersList(TRIGGER_SOURCE_TYPE.BEACONS_SOURCE_TYPE)
      .then((response) => {
        if (response.result.success === true) {
          self.startBeaconService(response.data);
        } else {
          console.log(response.result.message);
        }
      })
      .catch(function (response) {
        console.log(response.message);
      });
  }

  fetchContentsMenu = () => {
    PkCmsContents.getContentsMenu().then((contentsMenu) => {
      localStorage.setItem('menu', JSON.stringify(contentsMenu.data));
    })
      .catch((err) => {
        console.log('load menu error', err);
      })
      .then(() => {
        let menu = [];
        let menuStr = localStorage.getItem('menu');
        if (menuStr != null) {
          menu = JSON.parse(localStorage.getItem('menu'));
        }
        else {
          console.log('load menu error');
        }
        this.props.setMenu(menu);
        this.showPreloader(false);
        this.$f7router.navigate('/main/', { reloadAll: true });
      });
  }

  showPreloader = (show) => {
    if (show) {
      this.$f7.preloader.show('black');
    }
    else {
      this.$f7.preloader.hide();
    }
  }
};


// #region Redux
const mapStateToProps = state => {
  return {
    menu: state.app.menu
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setMenu: (menu) => dispatch(actions.setMenu(menu)),
  }
};

// #endregion

// export default connect(mapStateToProps, mapDispatchToProps)(Home);
export default connect(mapStateToProps, mapDispatchToProps)(Splash);