import React from 'react';
import { connect } from 'react-redux';
import { Block, Page, Link, Panel, View, Row, Col, ListItem, List } from 'framework7-react';
import Commons from '../commons'

class Menu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            errorMessage: null,
            infoContentsData: null
        };
    }

    render() {

        return (
            <Panel left cover resizable  className="menu-panel">
              <View>
                <Page>
                  <Block textAlign="center" style={{ margin: '0', padding: '0' }}>
                    <Link panelClose style={{ width: '100%' }} href="/main/" view={'.view-main'}>
                      <Row style={{ width: '100%' }}>
                        <Col style={{ textAlign: 'left' }}>
                          <img src={'./img/logo/logo.png'} alt="logo" width="120" style={{ marginLeft: '10px', marginTop: '10px', marginBottom: '-10px' }} />
                        </Col>
                      </Row>
                    </Link>
                  </Block>
      
                  {this.getMenuItems()}
                </Page>
              </View>
            </Panel>
        )
    }

    getMenuItems = () => {

        let menuItems = this.props.menu ? this.props.menu : [];
        const ret = menuItems.map((item, index) => {
    //      const stringLink = item.code ? item.code : (item.items.length === 0 ? "/content-list/" : "/menu-list/");
    
   //       return (<ListItem key={'menu-item' + index} view={'.view-main'} panelClose link={stringLink} title={item.title} routeProps={{ 'menuItem': item, c1: item.id, c2: 0, c3: 0 }} />);
          return (<ListItem key={'menu-item' + index} view={'.view-main'} panelClose link={Commons.getMenuItemUrl(item)} title={item.title} />);
        });
    
        return <List>{ret}</List>;
      }
};

// #region Redux
const mapStateToProps = state => {
    return {
      menu: state.app.menu
    };
  };

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Menu);