import React from 'react';
import { connect } from 'react-redux';
import { Toggle, ListInput, BlockHeader, Page, Navbar, NavLeft, Link, NavTitle, List, ListItem, Button, Block, Row, Col } from 'framework7-react';
import localeStrings from './profilo-local';
import { PkCordova, PkCordovaNotifications } from 'pikkart-cordova';
import { AnalyticsRegister, ANALYTICS_EVENT, ANALYTICS_SCREEN } from '../analytics-register'
import { locale } from 'moment';

class Profilo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            notificationEnabled: false,
            profileRole: null,
            birthDate: null
        };

        let dateDefault = '1994-07-16';
    }

    render() {
        const self = this;

        const jsonStringProfileData = localStorage.getItem('profile_data');
        let birthDate = this.state.birthDate;
        let profileRole = this.state.profileRole;

        if (jsonStringProfileData) {
            const profileData = JSON.parse(jsonStringProfileData);
            birthDate = profileData.birthDate;
            profileRole = profileData.profileRole;
        }

        return (
            <Page>
                <Navbar>
                    <NavLeft>
                        <Link panelOpen="left" color="black" iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu"></Link>
                    </NavLeft>
                    <NavTitle>{localeStrings.title}</NavTitle>
                </Navbar>
                <BlockHeader>{localeStrings.who}</BlockHeader>
                <Block>
                    <Row>
                        <Col>
                            <Button style={{ margin: '5px' }}
                                disabled={profileRole ? true : false}
                                color={(!profileRole || profileRole === 'tourist') ? "red" : "gray"}
                                className="col button button-raised button-fill"
                                onClick={() => this.radioChanged('tourist')}>
                                {localeStrings.tourist}
                            </Button>
                        </Col>
                        <Col>
                            <Button style={{ margin: '5px' }}
                                disabled={profileRole ? true : false}
                                color={(!profileRole || profileRole === 'citizen') ? "red" : "gray"}
                                className="col button button-raised button-fill"
                                onClick={() => this.radioChanged('citizen')}>
                                {localeStrings.citizen}
                            </Button>
                        </Col>
                    </Row>
                </Block>
                <BlockHeader>{localeStrings.birth_title}</BlockHeader>
                <List simpleList>
                    <ListInput
                        type="datepicker"
                        placeholder={birthDate ? birthDate : localeStrings.birth_placeholder}
                        disabled={birthDate ? true : false}
                        readonly
                        calendarParams={
                            {
                                openIn: 'customModal', header: true, footer: true, dateFormat: 'MM dd yyyy',
                                on: {
                                    close: function () {
                                        self.calendarChanged(
                                            this.value[0].getDate()+" "+
                                            (this.value[0].getMonth()+1)+" "+
                                            this.value[0].getFullYear());
                                    }
                                }
                            }
                        }
                    />
                </List>

                <BlockHeader>{localeStrings.notifications_title}</BlockHeader>

                <List simpleList>
                    <ListItem>
                        <span>{localeStrings.notifications_label}</span>
                        <Toggle
                            checked={this.state.notificationEnabled} onToggleChange={(e) => { this.onToggleChange(e) }} />
                    </ListItem>
                </List>
            </Page>)
    }

    radioChanged = (radioValue) => {
        const self = this;
        let text = localeStrings.confirm_data + " (" +(radioValue==="tourist" ? localeStrings.tourist : localeStrings.citizen)+")?";
        this.openConfirm(text, () => {
            self.setState({ profileRole: radioValue }, () => {
                self.saveProfileData(self.state.notificationEnabled, radioValue, self.state.birthDate);
            })
        });
    }

    calendarChanged = (calendarValue) => {
        const self = this;
        let text = localeStrings.confirm_data + " (" + calendarValue+")?";
        this.openConfirm(text, () => {
            self.setState({ birthDate: calendarValue }, () => {
                self.changeLocalProfileData(self.state.notificationEnabled, self.state.profileRole, calendarValue);
            })
        });
    }

    onToggleChange = (toggleValue) => {

        const onResume = () => {


            const resultFn = (notificationsEnabled) => {
                this.setState({ notificationEnabled: notificationsEnabled }, () => {
                    //self.changeLocalProfileData(notificationsEnabled, self.state.profileRole, self.state.birthDate);
                })
            }

            PkCordovaNotifications.areNotificationsEnabled().then(() => {
                resultFn(true);
            }).catch((error) => {
                resultFn(false);
            });

        }

        if (PkCordova.isCordova()) {
            PkCordovaNotifications.goToNotificationSettings(onResume);
        }
        //TODO: gestire il caso in cui non sia mobile
        /*else {
            if (toggleValue) {
                PkCmsNotifications.initWebNotifications(initializedFirebaseApp, process.env.FIREBASE_WEB_KEY);
                PkCmsNotifications.checkServiceRegistration()
                    .then(function (successResult) {
                        if (successResult === true)
                            console.log(successResult);
                        else {
                            const errorMsg = 'please enabled notification for this app';
                            console.log(errorMsg);
                        }
                    }).catch(function (errorMessage) {
                        console.log('checkServiceRegistration error', errorMessage);
                    });
            }
        }*/
    }

    changeLocalProfileData = (notificationEnabled, profileRole, birthDate) => {
        localStorage.setItem('profile_data', JSON.stringify({
            notificationEnabled: notificationEnabled,
            profileRole: profileRole,
            birthDate: birthDate
        }));
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        /*notificationEnabled: false,
            profileRole: 'tourist',
            birthDate: '1994-07-16'*/
        if (this.state.notificationEnabled !== prevState.notificationEnabled ||
            this.state.profileRole !== prevState.profileRole ||
            this.state.birthDate !== prevState.birthDate) {

        }
    }

    saveProfileData = (notificationEnabled, profileRole, birthDate) => {
        this.changeLocalProfileData(notificationEnabled, profileRole, birthDate);

        this.registerAnalyticsEvent(ANALYTICS_EVENT.SAVE_PROFILE_DATA, this.state.profileRole, this.state.birthDate);
    }

    registerAnalyticsEvent = (eventName, profileRole, birthDate) => {
        AnalyticsRegister.logEvent(eventName, { ProfileRole: profileRole, BirthDate: birthDate });
    }

    openConfirm(text, callback) {
        const app = this.$f7;
        app.dialog.confirm(text, localeStrings.confirm_data_title,  () => {
            callback();
        }, null);
    }

    componentDidMount = () => {
        AnalyticsRegister.logEvent(ANALYTICS_SCREEN.PROFILE);

        //console.log(localStorage.getItem('profile_birth_date') ? new Date(localStorage.getItem('profile_birth_date')) : null);

        const jsonStringProfileData = localStorage.getItem('profile_data');

        if (jsonStringProfileData) {
            const profileData = JSON.parse(jsonStringProfileData);
            this.setState({
                notificationEnabled: false,
                profileRole: profileData.profileRole,
                birthDate: profileData.birthDate
            })
        }

        const resultFn = (notificationsEnabled) => {
            console.log('notificationEnabled result');
            this.setState({ notificationEnabled: notificationsEnabled })
        }

        if (PkCordova.isCordova()) {
            PkCordovaNotifications.areNotificationsEnabled().then(() => {
                resultFn(true);
            }).catch((error) => {
                resultFn(false);
            });
        }
        /*else {
            PkCmsNotifications.checkServiceRegistration().then(function (successResult) {
                if (successResult === true)
                    resultFn(true);
                else {
                    resultFn(false);
                }
            })
                .catch(function (errorMessage) {
                    resultFn(false);
                });
        }*/
    }

    componentWillUnmount = () => {
        console.log('componentWillUnmount');
    }
};

// #region Redux
const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Profilo);