import * as firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBvshcPhCY3Avp5L5KLFpwkS9ASc_bXfYs",
    authDomain: "reggio-smart.firebaseapp.com",
    databaseURL: "https://reggio-smart.firebaseio.com",
    projectId: "reggio-smart",
    storageBucket: "reggio-smart.appspot.com",
    messagingSenderId: "272097373911",
    appId: "1:272097373911:web:e4d4a5dc1bfe63536d4a92",
    measurementId: "G-DQCZMWD82X"
  });

export { initializedFirebaseApp };