
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    exitAppTitle: 'Exit',
    exitAppText: 'Do you want to exit the app?',
  },
  it: {
    exitAppTitle: 'Esci',
    exitAppText: 'Vuoi uscire dall\'app?',
  }
});
  
  export default localeStrings;