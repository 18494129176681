import Landing from './containers/landing.jsx';
import Main from './containers/main.jsx';
import Splash from './containers/splash.jsx'
import NotFound from './containers/404.jsx';

import Profilo from './containers/menu-screens/profilo.jsx'
import Credits from './containers/menu-screens/credits.jsx'
import Tutorial from './containers/menu-screens/tutorial.jsx'
import Notifications from './containers/menu-screens/notifications.jsx'

import MenuList from './containers/contents/menu-list.jsx'
import ContentList from './containers/contents/content-list.jsx'
import ContentKML from './containers/contents/content-kml.jsx'

import ContentDetail from './containers/contents/content-detail.jsx'
import RequestedContentDetail from './containers/contents/requested-content-detail.jsx'
import Commons from './containers/commons'

import { AnalyticsRegister } from './containers/analytics-register'

// Pages
export default [
  // Index page
  {
    path: '/',
    name: 'root',
    component: Landing,
    alias: '/index.html'
  },
  {
    name: 'splash',
    path: '/splash/',
    component: Splash,
  },
  {
    name: 'main',
    path: '/main/',
    component: Main,
  },
  {
    name: 'profilo',
    path: '/profilo',
    component: Profilo,
  },
  {
    name: 'settings',
    path: '/settings',
    component: Profilo,
  },
  {
    name: 'Credits',
    path: '/credits',
    component: Credits,
  },
  {
    name: 'Tutorial',
    path: '/tutorial',
    component: Tutorial,
  },
  {
    name: 'Notifications',
    path: '/notifications',
    component: Notifications,
  },
  {
    name: 'Content-kml',
    path: '/content-kml',
    component: ContentKML,
  },
  {
    path: '/content-detail/:contentId',
    component: ContentDetail,

    async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
    }
  },
  {
    path: '/requested-content-detail/:contentId',
    component: RequestedContentDetail,

    async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
    }
  },

  // Default route (404 page). MUST BE THE LAST
  {
    path: '(.*)',
    async: function (routeTo, routeFrom, resolve, reject) {

      console.log("routeTo=", routeTo);

      let menuStr = localStorage.getItem('menu');
      if (menuStr != null) {
        let menu = JSON.parse(localStorage.getItem('menu'));

        let menuItem = Commons.getMenuItemFromRoute(routeTo, menu);

        if (menuItem) {

          let resolveArgs = { component: menuItem.items.length > 0 ? MenuList : ContentList };
          let resolveOptions = { props: { 'menuItem': menuItem, ['c' + menuItem.catIndex]: menuItem.id } }

          AnalyticsRegister.setCurrentScreen(menuItem.title);
          resolve(resolveArgs, resolveOptions);
        }
        else {
          resolve({ component: NotFound });
        }
      }
      else {
        resolve({ component: NotFound });
      }
    },
  },
];
