import appActionTypes from './actionTypes';


export const setOrientation = (orientation) => {
  return {
    type: appActionTypes.SET_ORIENTATION,
    orientation: orientation,
  };
}

export const setNotificationServiceError = (notificationServiceError) => {
  return {
    type: appActionTypes.SET_NOTIFICATION_SERVICE_ERROR,
    notificationServiceError: notificationServiceError,
  }
}

export const setMainPageIndex = (mainPageIndex) => {
  return {
    type: appActionTypes.SET_MAINPAGE_INDEX,
    mainPageIndex: mainPageIndex,
  }
}

export const setMenu = (menu) => {
  return {
    type: appActionTypes.SET_MENU,
    menu: menu,
  }
}

export const setSkippedArLogoTutorial = (skippedArLogoTutorial) => {
  return {
    type: appActionTypes.SET_SKIPPED_AR_LOGO_TUTORIAL,
    skippedArLogoTutorial: skippedArLogoTutorial,
  }
}

export const setSkippedDiscoverTutorial = (skippedDiscoverTutorial) => {
  return {
    type: appActionTypes.SET_SKIPPED_DISCOVER_TUTORIAL,
    skippedDiscoverTutorial: skippedDiscoverTutorial,
  }
}

export const setReceivedNotifications = (receivedNotifications) => {
  return {
    type: appActionTypes.SET_RECEIVED_NOTIFICATIONS,
    receivedNotifications: receivedNotifications,
  }
}

export const setFavoriteContents = (favoriteContents) => {
  return {
    type: appActionTypes.SET_FAVORITE_CONTENTS,
    favoriteContents: favoriteContents,
  }
}

