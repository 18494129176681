import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        title: "Credits",
        editorial_staff: "Editorial staff",
    },
    it: {
        title: "Credits",
        editorial_staff: "Redazione",
    }
});

export default localeStrings;