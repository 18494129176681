import React from 'react';
import { PkCmsDiscover, PkCmsContents } from 'pikkart-cms';
import Commons from './commons'
import { Page, PageContent } from 'framework7-react';
import store from '../redux/store'
import { PkCordova, PkCordovaUi, PkCordovaAuthentication, PkCordovaNotifications } from 'pikkart-cordova';
import { PkCms, PkCmsNotifications, PLATFORM_TYPE } from 'pikkart-cms';

import { setReceivedNotifications } from '../redux/actions';

import localeStrings from './landing-local';

class Landing extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (<Page><PageContent><div></div></PageContent></Page>);
  }

  componentDidMount = () => {

    this.initCordovaOnDevice();

    const contentSuccess = (content) => {
      this.$f7router.navigate(Commons.getRequestedContentDetailUrl(content.data.content), { props: { contentItem: content.data } });
    }

    const goToSplash = () => {
      setTimeout(() => {
        let path = (this.props.returnPath) ? this.props.returnPath : '/splash/';
        console.log("path " + path);
        this.$f7router.navigate(path, { reloadAll: true });
      }, 300);
    }

    console.log("f7route " + JSON.stringify(this.$f7route));
    console.log("f7route.query " + JSON.stringify(this.$f7route.query));
    console.log("f7route.query.keypointid " + JSON.stringify(this.$f7route.query.keypointid));

    if (this.$f7route && this.$f7route.query) {
      if (this.$f7route.query.keypointid) {
        setTimeout(() => {
          PkCmsDiscover.getKeypointContentData(this.$f7route.query.keypointid)
            .then((response) => {
              contentSuccess(response);
              // this.$f7router.navigate(Commons.getContentDetailUrl(response.data.content), { props: {contentItem: response.data }});
            })
            .catch((error) => {
              console.error(error);
              this.$f7router.navigate('/404/', { reloadAll: true });
            });
        }, 300);
      } else if (this.$f7route.query.contentid) {
        setTimeout(() => {
          PkCmsContents.getInfoContentData(this.$f7route.query.contentid)
            .then((response) => {
              contentSuccess(response);
              // this.$f7router.navigate(Commons.getContentDetailUrl(response.data.content), { props: {contentItem: response.data }});
            })
            .catch((error) => {
              console.error(error);
              this.$f7router.navigate('/404/', { reloadAll: true });
            });
        }, 300);
      }
      else {
        goToSplash();
      }

    } else {
      goToSplash();
    }

    //PkCmsAuth.doLogin('reggioemilia', 'X1iIKr6X').then((response) => {
    //console.log('doLogin', response.result);
    //});
  }

  initCordovaOnDevice = () => {
    document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
  }

  onDeviceReady = () => {
    console.log('onDeviceReady');

    PkCordovaUi.lockOrientation();
    //store.dispatch(RaReduxActions.setCordovaReady(true));
    PkCordova.init_cordova();

    const deviceConfig = {
      apiUrl: process.env.REACT_APP_CMS_API_URL,
      apiKey: process.env.REACT_APP_CMS_API_KEY,
      deviceId: 'browserweb',
      platformType: PLATFORM_TYPE.WEB
    };

    if (PkCordovaAuthentication.getDeviceId().then((deviceId) => {

      deviceConfig.deviceId = deviceId;
      const platform = PkCordova.getPlatform();

      switch (platform) {
        case 'android':
          deviceConfig.platformType = PLATFORM_TYPE.ANDROID;
          break;
        case 'ios':
          deviceConfig.platformType = PLATFORM_TYPE.IOS;
          break;
        default:
          deviceConfig.platformType = PLATFORM_TYPE.WEB;
          break;
      }

      PkCms.init(deviceConfig);

      PkCmsNotifications.initCordovaNotifications(onMessageReceived);
    }));

    const onMessageReceived = (message) => {
      console.log("onMessageReceived", message);
      PkCordovaNotifications.getReceivedMessages().then(function (messages) {
        console.log("messages", messages);
        store.dispatch(setReceivedNotifications(messages));
      });
    }

    PkCordovaNotifications.getReceivedMessages().then(function (messages) {
      console.log("messages", messages);
      store.dispatch(setReceivedNotifications(messages));
    });

    PkCordovaNotifications.getLatestNotificationMessage().then(function (message) {
      console.log("app started with notification", JSON.parse(message));
    })
      .catch((errorMessage) => {
        console.log(errorMessage);
      });



    /*********************************
     * TASTO BACK DEL DEVICE
     ********************************/
    this.processingBackbuttonRequest = false;
    const self = this;
    document.addEventListener("backbutton", () => {

      console.log('backButton', 'back button pressed');

      //gestisco un semaforo perché questo componente è utilizzato da tutte le pagine, quindi questo evento viene scatenato n volte contemporaneamente
      //non posso fare diversamente perché l'oggetto router è accessibile solo dalle pagine dichiarate nel router, e questo mi impedisce di fare redirect in AppBoot, Landing o altre pagine centralizzate
      if (self.processingBackbuttonRequest === false) {
        self.processingBackbuttonRequest = true;
        if (self.$f7router.currentRoute.url !== "/main/") {
          self.$f7router.navigate('/main/', { reloadAll: true });

          setTimeout(() => {
            self.processingBackbuttonRequest = false;
          }, 2000);
        }
        else {

          self.$f7.dialog.confirm(localeStrings.exitAppText, localeStrings.exitAppTitle, () => {
            navigator.app.exitApp();
          }, () => {

            setTimeout(() => {
              self.processingBackbuttonRequest = false;
            }, 2000);
          });
        }
      }

    }, false);
  }
};

export default Landing;
