/*
import { combineReducers } from 'redux'
import appReducer from './appReducer';

export default () => {
    
    return combineReducers({
        app : appReducer
    });

} 
*/


import { combineReducers } from 'redux'
import appReducer from './appReducer';
import {PkCmsReduxReducer} from 'pikkart-cms';

export default () => {
    
    return combineReducers({
        app : appReducer,
        cms : cmsReducer
    });

    function cmsReducer (state, action) {
        PkCmsReduxReducer.setReducerName('cms');
        return PkCmsReduxReducer.getReducer(state, action);
    }
} 
