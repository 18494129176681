
import LocalizedStrings from 'react-localization';

const appLocaleStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    no_contents: "No contents found"
  },
  it: {
    no_contents: "Nessun contenuto trovato"
  }
});
  
  export default appLocaleStrings;